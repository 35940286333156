<!-- Name -->
<script lang="ts">
export default {
  name: 'app-module-news-highlights'
};
</script>

<script lang="ts" setup>

const { $api } = useNuxtApp();

const formData = new FormData();
formData.append('limit',2);

let news;

const { data, error } = await $api.news.getNews(formData,{
    pick: ['payload']
});

if (!error.value) {
  news = data.value.payload.data;
}
</script>

<template lang="pug">
article.container(v-if="news")
  .row 
    .col-12.text-center.my-3.my-lg-5 
      h2.headline--2 Actualidad
    .col-md-6.mb-4(v-for="(item, index) in news", :key="index") 
        UiAppCardNews(
            :key="index",
            :img="item.thumbnail", 
            :titulo="item.titulo", 
            :fecha="item.fecha", 
            :slug="item.slug"
            :h2="false")
    .col-12.text-center.mt-4 
      NuxtLink.btn.btn--primary(to="/noticias/") Ver toda la actualidad
</template>