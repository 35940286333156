<!-- Name -->
<script lang="ts">
export default {
  name: 'app-bands-history'
};
</script>

<script lang="ts" setup>
import { useBrandsInfoStore } from '@/store/brandsInfo';

const brandInfoStore = useBrandsInfoStore();
const { getActiveBrands } = brandInfoStore;
const selected = ref('');
const { $api } = useNuxtApp();


const options = {
  type   : 'loop',
  perPage : 6,
  pagination: false,
  isNavigation: true,
  breakpoints:{
    470: {
      perPage : 3,
    },
    576: {
      perPage : 4,
    },
    650: {
      perPage : 5,
    },
    992: {
      perPage : 6,
    },
    1200: {
      perPage : 4,
    },
    1300: {
      perPage : 5
    }
  },
  arrowPath: 'M22.2484 0.781051L39.219 17.7516C40.2604 18.793 40.2603 20.4815 39.219 21.5229L22.2484 38.4934C21.207 39.5348 19.5185 39.5348 18.4771 38.4934C17.4358 37.452 17.4358 35.7636 18.4772 34.7222L30.8954 22.3039L-1.94987e-06 22.3039L-1.48361e-06 16.9706L30.8954 16.9706L18.4772 4.55229C17.4358 3.51089 17.4358 1.82245 18.4772 0.78105C19.5186 -0.260348 21.207 -0.260348 22.2484 0.781051Z'
};

/**
 * Carga las marcas
 */
 const AsyncMarcas= async ()=>{
  const { data, error } = await $api.brands.index({
    pick:['payload']
  });

  if (error.value) {
    throw createError({ statusCode: 500, statusMessage: error.value?.data?.message || error.value.message })
  }
  
  return data.value.payload;
};


const responseMarcas=await AsyncMarcas();

const brands= getActiveBrands();

//Ordena las marcas
brands.sort((a, b) => {
    if (a.orden === undefined && b.orden === undefined) {
        return 0;
    }
    if (a.orden === undefined) {
        return 1;
    }
    if (b.orden === undefined) {
        return -1;
    }
    return a.orden - b.orden;
});

const brandsSlugs=brands.map(el=>el['slug']).filter(item => item !== ('marcos-cycles' || 'marcos-ocasion') );


const onMove = (event: Event) => {
  selected.value = brandsSlugs[event.index];
};

const onClick = async (brand) => {
  await navigateTo(`/${brand}/`);
};


</script>

<template lang="pug">
splide.app-brands-home.mt-3(
      :options="options",
      @splide:move="onMove")
      splide-slide(v-for="(item, index) in brandsSlugs", :key="index")
        .app-brands-home__item(@click.prevent="onClick(item)")
          .app-brands-home__item-inner.text-center
            span(:class="[`icon-${item}`, {'adjust-cycles': item === 'marcos-cycles'},{'adjust-ocasion': item === 'marcos-ocasion'}]")

</template>

<style lang="scss" >
@import 'index';
</style>